import React, { useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import Slider from "react-slick";
import Section from "../components/section";
import GalleryNextIcon from "../components/icons/gallery-next-icon";
import GalleryPrevIcon from "../components/icons/gallery-prev-icon";
import media from "../styles/media";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Module Services Fragment
export const moduleImageGalleryFragment = graphql`
  fragment ModuleImageGalleryFragment on Node {
    ... on ContentfulModuleImageGallery {
      __typename
      contentful_id
      name
      images {
        title
        fluid(
          maxWidth: 1200
          quality: 95
          toFormat: JPG
          cropFocus: FACES
          resizingBehavior: FILL
        ) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <GalleryNextIcon />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <GalleryPrevIcon />
    </div>
  );
};

const ModuleImageGallery = ({ images }) => {
  const [galleryIndex, setGalleryIndex] = useState(0);

  const settings = {
    dots: false,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Section>
      <StyledContainer>
        <StyledTitle>Our Values</StyledTitle>
      </StyledContainer>
      <StyledContainer>
        <StyledImgWrapp>
          <Slider {...settings}>
            {images.map((image, index) => {
              return (
                <Img
                  key={index}
                  fluid={image.fluid}
                  title="belolabs-about-image"
                />
              );
            })}
          </Slider>
        </StyledImgWrapp>
      </StyledContainer>
    </Section>
  );
};

export default ModuleImageGallery;

const StyledContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0px 30px;
  ${media.tablet`gap: 0px 15px;`};
  ${media.thone`display: flex; flex-direction: column-reverse;  padding-bottom: 40px; padding-top: 40px;`};

  .slick-prev {
    left: -71px;
  }
  .slick-prev,
  .slick-next {
    width: 44px;
    height: 23px;
    transform: translateX(-132px);
    ${media.giant`transform: translateX(-112px);`};
    ${media.bigDesktop`transform: translateX(-102px);`};
    ${media.desktop`transform: translateX(-72px);`};
    ${media.tablet`transform: translateX(-52px);`};
    ${media.thone`
      top: 107%;
      transform: translateX(0px);
    `};
  }
  .slick-next {
    left: 0;
    ${media.thone`left: 52%;`};
    ${media.phone`left: 55%;`};
  }
  .slick-prev {
    ${media.thone`left: 36%;`};
    ${media.phone`left: 27%;`};
  }
  .slick-prev:before,
  .slick-next:before {
    display: none;
  }
`;

const StyledTitle = styled.div`
  padding-top: 72px;
  grid-column: 2 / -1;
  font-style: normal;
  font-weight: 300;
  font-size: 2rem;
  line-height: 108.1%;
  min-width: 390px;
  ${media.bigDesktop`font-size: 1.8rem; min-width: 290px;`};
  ${media.desktop`font-size: 1.5rem; min-width: 240px;`};
  ${media.tablet`min-width: auto;`};
  ${media.thone`font-size: 1.3rem; max-width: 231px; padding-bottom: 40px; padding-top: 40px;`};
`;

const IconsWrapp = styled.div`
  width: 100%;
  grid-column: 2 / span 2;
  display: flex;
  justify-content: space-around;
  align-items: center;
  ${media.tablet`grid-column: 1 / span 2;`};
  ${media.thone`
    display: block;
    padding-top: 35px;
    width: fit-content;
    margin: 0 auto;
    `};
  svg {
    cursor: pointer;
    margin-right: 8px;
  }
`;

const StyledImgWrapp = styled.div`
  grid-column: 4 / -1;
  width: 100%;
  ${media.phablet`display: block;`};
`;
