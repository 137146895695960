import React, { useRef } from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import Layout from "../components/layout";
import moduleConductor from "../modules/module-conductor";

const Page = ({ location, data }) => {
  const {
    pageData: { seo, sections },
  } = data;

  const pageLayoutRef = useRef();
  let modulesMarkup = null;
  let extraProps = { location: location };
  extraProps.pageLayoutRef = pageLayoutRef;
  if (sections) {
    let count = 0;
    modulesMarkup = sections.map((module, i) => {
      if (module.__typename === "ContentfulModuleProject") {
        count = count + 1;
        extraProps.projectIndex = count;
      }
      return moduleConductor(module, i, extraProps);
    });
  }

  return (
    <div ref={pageLayoutRef}>
      <SEO data={seo} location={location}/>
      <Layout location={location}>{modulesMarkup}</Layout>
    </div>
  );
};

export default Page;

export const pageQuery = graphql`
  query($contentfulId: String!) {
    pageData: contentfulTemplatePage(contentful_id: { eq: $contentfulId }) {
      id
      contentful_id
      slug
      seo {
        ...GlobalSEOFragment
      }
      sections {
        ...ModuleHeroFragment
        ...ModuleServiceFragment
        ...ModuleProjectFragment
        ...ModuleTitleAndDetailsFragment
        ...ModuleImageGalleryFragment
        ...ModuleFooterFragment
      }
      footer
    }
  }
`;
