import React from "react";

const GalleryPrevIcon = () => {
  return (
    <svg
      width="44"
      height="23"
      viewBox="0 0 44 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.5 22L1 11.5L11.5 1" stroke="black" strokeWidth="0.724138" />
      <path
        d="M1.02588 11.5L43.3633 11.5"
        stroke="black"
        strokeWidth="0.834731"
      />
    </svg>
  );
};

export default GalleryPrevIcon;
