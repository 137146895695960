import React from "react";

const GalleryNextIcon = () => {
  return (
    <svg
      width="43"
      height="23"
      viewBox="0 0 43 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.8633 1L42.3633 11.5L31.8633 22"
        stroke="black"
        strokeWidth="0.724138"
      />
      <path d="M42.3374 11.5L0 11.5" stroke="black" strokeWidth="0.834731" />
    </svg>
  );
};

export default GalleryNextIcon;
