import React from "react";
import styled from "styled-components";
import AnimationLink from "../../components/animation-link";
import Section from "../../components/section";
import media from "../../styles/media";

const WorkHero = (props) => {
  const { title, logoImage, pageLayoutRef } = props;
  return (
    <Layout>
      <Section>
        {logoImage && (
          <AnimationLink navLink={`/`} pageLayoutRef={pageLayoutRef}>
            <MenuLogo>
              <LogoText>belo.</LogoText>
            </MenuLogo>
          </AnimationLink>
        )}
        <StyledContainer>
          <StyledGridWrapp>
            {title && <StyledTitle>{title}</StyledTitle>}
          </StyledGridWrapp>
        </StyledContainer>
      </Section>
    </Layout>
  );
};

export default WorkHero;

const Layout = styled.div`
  position: relative;
`;

const MenuLogo = styled.div`
  position: relative;
  top: 40px;
  left: 0px;
  width: 99px;
  height: 39px;
  z-index: 999;
`;

const LogoText = styled.div`
  letter-spacing: -0.5px;
  font-size: 2.7rem;
`;

const StyledContainer = styled.div`
  width: 100%;
`;

const StyledGridWrapp = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0px 30px;
  ${media.phone`gap: 0px 0px;`};
`;

const StyledTitle = styled.div`
  margin-top: 76px;
  grid-column: 1 / -1;
  font-style: normal;
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 91.6%;
  transform: translateY(24px);
`;
