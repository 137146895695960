import React from "react";
import { graphql } from "gatsby";
import AnimationLink from "../components/animation-link";
import Img from "gatsby-image";
import styled from "styled-components";
import Section from "../components/section";
import BlackArrowIcon from "../components/icons/black-arrow-icon";
import media from "../styles/media";

// Module Service Fragment
export const moduleServiceFragment = graphql`
  fragment ModuleServiceFragment on Node {
    ... on ContentfulModuleService {
      __typename
      contentful_id
      name
      title
      slug
      featuredWork {
        name
        title
        coverImage {
          title
          fluid(
            maxWidth: 800
            quality: 95
            toFormat: JPG
            cropFocus: FACES
            resizingBehavior: FILL
          ) {
            ...GatsbyContentfulFluid
          }
        }
        illustrations {
          title
          fluid(
            maxWidth: 800
            quality: 95
            toFormat: JPG
            cropFocus: FACES
            resizingBehavior: FILL
          ) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

const ModuleService = (props) => {
  const { title, slug, featuredWork, pageLayoutRef } = props;
  return (
    <Section>
      <StyledContainer>
        <ContentWrapp>
          <StyledTitleWrapp>
            <StyledPoint />
            <StyledTitle>{title}</StyledTitle>
          </StyledTitleWrapp>
          <AnimationLink
            navLink={`/services/${slug}`}
            pageLayoutRef={pageLayoutRef}
          >
            <StyledButton>
              Learn More <BlackArrowIcon />
            </StyledButton>
          </AnimationLink>
        </ContentWrapp>
        <StyledImgWrapp>
          <StyledProjectImgWrapp>
            <Img
              fluid={featuredWork[0].coverImage.fluid}
              title="belolabs-logo"
            />
            <StyledProjectTitle className="first-project-title">
              {featuredWork[0].title}
            </StyledProjectTitle>
          </StyledProjectImgWrapp>
          <StyledProjectImgWrapp>
            {featuredWork[1] && (
              <>
                <Img
                  fluid={featuredWork[1].coverImage.fluid}
                  title="belolabs-logo"
                />
                <StyledProjectTitle>{featuredWork[1].title}</StyledProjectTitle>
              </>
            )}
          </StyledProjectImgWrapp>
        </StyledImgWrapp>
        <StyledLine />
      </StyledContainer>
    </Section>
  );
};

export default ModuleService;

const StyledContainer = styled.div`
  padding-top: 72px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0px 30px;
  ${media.tablet`gap: 0px 15px;`};
  ${media.thone`display: block; padding-top: 50px;`};
`;

const StyledLine = styled.div`
  grid-column: 2 / -1;
  padding-bottom: 88px;
  border-bottom: 0.5px solid #000000;
  ${media.thone`padding-bottom: 50px;`};
`;

const ContentWrapp = styled.div`
  width: 100%;
  grid-column: 2 / span 4;
`;

const StyledTitleWrapp = styled.div`
  display: flex;
  transform: translateX(-62px);
  ${media.desktop`transform: translateX(-54px);`};
  ${media.thone`transform: translateX(0px);`};
`;

const StyledButton = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 91.6%;
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  padding-top: 30px;
  svg {
    margin-left: 20px;
    ${media.thone`width: 30px; margin-left: 8px;`};
  }
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.41, 0, 0.019, 1);
  &:hover {
    svg {
      transform: translateX(10px);
      transition-duration: 0.3s;
    }
  }
  ${media.thone`margin-left: 36px; margin-bottom: 42px; font-size: 0.875rem;`};
`;

const StyledImgWrapp = styled.div`
  grid-column: 6 / -1;
  display: flex;
  align-items: center;
  div:first-child {
    padding-right: 33px;
    ${media.desktop`padding-right: 15px;`};
    ${media.phablet`padding-right: 0px;`};
  }
  ${media.phablet`display: block;`};
`;

const StyledProjectImgWrapp = styled.div`
  flex: 1;
  height: 100%;
  .gatsby-image-wrapper {
    height: 100%;
    object-fit: cover;
    width: auto;
  }
  .first-project-title {
    ${media.phablet`margin-bottom: 41px;`};
  }
`;

const StyledPoint = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: black;
  padding-right: 32px;
  margin-right: 30px;
  margin-top: 8px;
  ${media.bigDesktop`width: 28px; height: 28px; margin-top: 8px; padding-right: 28px;`};
  ${media.desktop`width: 24px; height: 24px; margin-top: 4px; padding-right: 24px; margin-right: 22px;`};
  ${media.thone`width: 20px; height: 20px; margin-top: 4px; padding-right: 20px; margin-right: 16px;`};
`;

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 2rem;
  line-height: 108.1%;
  min-width: 370px;
  ${media.bigDesktop`font-size: 1.8rem; min-width: 290px;`};
  ${media.desktop`font-size: 1.5rem; min-width: 240px;`};
  ${media.tablet`min-width: auto;`};
  ${media.thone`font-size: 1.3rem; max-width: 231px;`};
`;

const StyledProjectTitle = styled.div`
  margin-top: 8px;
  font-style: normal;
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 136.6%;
`;
