import React from "react";
import { useForm } from "@formcarry/react";
import styled from "styled-components";
import ArrowIcon from "./icons/arrow-icon";
import media from "../styles/media";
import theme from "../styles/theme";

const { fonts } = theme;

const ContactForm = ({ formcarryId }) => {
  // Call the `useForm` hook in your function component
  const { state, submit } = useForm({
    id: formcarryId,
  });
  // Success message
  if (state.submitted) {
    return <div>Thank you! We received your submission.</div>;
  }

  return (
    <StyledFormContainer>
      <form onSubmit={submit}>
        <label htmlFor="fname" className="full-name-label">
          Full Name <span>*</span>
        </label>
        <br />
        <input
          type="text"
          id="fname"
          name="fname"
          className="full-name-input"
          placeholder="John Doe"
          required
        />

        <br />
        <label htmlFor="email" className="email-label">
          Your Email<span>*</span>
        </label>
        <br />
        <input
          id="email"
          type="email"
          name="email"
          className="email-input"
          required
        />
        <br />
        <label htmlFor="phone" className="phone-label">
          Phone
        </label>
        <br />
        <input type="tel" id="phone" name="phone" className="phone-input" />
        <br />
        <label htmlFor="company" className="company-label">
          Company
        </label>
        <br />
        <input
          type="text"
          id="company"
          name="company"
          className="company-input"
        />
        <br />
        <label htmlFor="message" className="message-label">
          Message
        </label>
        <br />
        <textarea id="message" name="message" className="message-textarea" />
        <br />
        <button type="submit" className="request-button">
          Send Request <ArrowIcon />
        </button>
      </form>
    </StyledFormContainer>
  );
};

export default ContactForm;

const StyledFormContainer = styled.div`
  width: 100%;
  input {
    border: 0px;
    border-bottom: 1px solid #c4c4c4;
    margin-top: 12px;
    margin-bottom: 36px;
    outline: none;
    width: 100%;
  }
  label {
    font-style: normal;
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 91.6%;
    color: #818181;
    span {
      margin-left: 4px;
      color: red;
    }
  }
  .message-textarea {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 36px;
    outline: none;
    border: 0px;
    height: 70px;
    border-bottom: 1px solid #c4c4c4;
  }
  button {
    font-family: ${fonts.neueHaasGroteskDisplay};
    font-style: normal;
    font-weight: 300;
    font-size: 1.3rem;
    line-height: 100.1%;
    color: #00d4c1;
    background: none;
    border: 0px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    ${media.bigDesktop`
      font-size: 1.1rem;
    `};

    svg {
      height: 24px;
      transition-duration: 0.3s;
      transition-timing-function: cubic-bezier(0.41, 0, 0.019, 1);
      ${media.bigDesktop`height: 18px; margin-left: 4px;`};
      margin-left: 8px;
    }
    &:hover {
      svg {
        transform: translateX(8px);
      }
    }
  }
`;
