import React from "react";
import ModuleHero from "./hero/hero-conductor";
import ModuleService from "./module-service";
import ModuleTitleAndDetails from "./module-titleanddetails";
import ModuleProject from "./module-project";
import ModuleImageGallery from "./module-image-gallery";
import ModuleFooter from "./module-footer";

const moduleConductor = (props, key, extraProps) => {
  switch (props.__typename) {
    case "ContentfulModuleHero": {
      return <ModuleHero key={key} {...props} {...extraProps} />;
    }

    case "ContentfulModuleService": {
      return <ModuleService key={key} {...props} {...extraProps} />;
    }

    case "ContentfulModuleProject": {
      return <ModuleProject key={key} {...props} {...extraProps} />;
    }

    case "ContentfulModuleTitleAndDetails": {
      return <ModuleTitleAndDetails key={key} {...props} {...extraProps} />;
    }

    case "ContentfulModuleImageGallery": {
      return <ModuleImageGallery key={key} {...props} {...extraProps} />;
    }

    case "ContentfulModuleFooter": {
      return <ModuleFooter key={key} {...props} {...extraProps} />;
    }

    default: {
      return null;
    }
  }
};

export default moduleConductor;
