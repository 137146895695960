import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Section from "../components/section";
import media from "../styles/media";

export const moduleTitleAndDetailsFragment = graphql`
  fragment ModuleTitleAndDetailsFragment on Node {
    ... on ContentfulModuleTitleAndDetails {
      __typename
      contentful_id
      name
      title
      details {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

const ModuleTitleAndDetails = (props) => {
  const { title, details } = props;
  return (
    <Section>
      <StyledContainer>
        <ContentWrapp>
          <StyledTitle>{title}</StyledTitle>
        </ContentWrapp>
        <StyledServicesWrapp
          dangerouslySetInnerHTML={{
            __html: details.childMarkdownRemark.html,
          }}
        />
      </StyledContainer>
    </Section>
  );
};

export default ModuleTitleAndDetails;

const StyledContainer = styled.div`
  padding-top: 72px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0px 30px;
  ${media.tablet`gap: 0px 15px;`};
  ${media.thone`display: block; padding-top: 40px; padding-bottom: 40px;`};
`;

const ContentWrapp = styled.div`
  width: 100%;
  grid-column: 2 / span 3;
  ${media.tablet`grid-column: 1 / span 4;`};
  ${media.thone`display: block; padding-bottom: 35px;`};
`;

const StyledServicesWrapp = styled.div`
  grid-column: 5 / -1;
  ${media.phablet`display: block;`};
  font-style: normal;
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 136.6%;
  ${media.bigDesktop`font-size: 1.2rem; grid-column: 5 / -1;`};
  ${media.desktop`font-size: 1.1rem;`};
  ${media.tablet`font-size: 1rem;`};
  transform: translateY(-1em);
`;

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 2rem;
  line-height: 108.1%;
  min-width: 390px;
  ${media.bigDesktop`font-size: 1.8rem; min-width: 290px;`};
  ${media.desktop`font-size: 1.5rem;  min-width: 240px;`};
  ${media.tablet`min-width: auto;`};
  ${media.thone`font-size: 1.3rem; max-width: 231px;`};
`;
