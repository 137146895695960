import React from "react";
import styled from "styled-components";
import AnimationLink from "../../components/animation-link";
import Section from "../../components/section";
import ContactForm from "../../components/contact-form";
import media from "../../styles/media";

const ServicesHero = (props) => {
  const { title, description, logoImage, formcarryId, pageLayoutRef } = props;
  return (
    <Layout>
      <Section>
        <AnimationLink navLink={`/`} pageLayoutRef={pageLayoutRef}>
          <MenuLogo>
            <LogoText>belo.</LogoText>
          </MenuLogo>
        </AnimationLink>

        <StyledContainer>
          <StyledGridWrapp>
            {title && <StyledTitle>{title}</StyledTitle>}
            {description && (
              <StyledDescription>
                <TabletContentWrapp>
                  {description.description}
                </TabletContentWrapp>
              </StyledDescription>
            )}
            {formcarryId && (
              <StyledContactForm>
                <ContactForm formcarryId={formcarryId} />
              </StyledContactForm>
            )}
          </StyledGridWrapp>
        </StyledContainer>
      </Section>
    </Layout>
  );
};

export default ServicesHero;

const Layout = styled.div`
  position: relative;
`;

const MenuLogo = styled.div`
  position: relative;
  top: 40px;
  left: 0px;
  width: 99px;
  height: 39px;
  z-index: 999;
`;

const LogoText = styled.div`
  letter-spacing: -0.5px;
  font-size: 2.7rem;
`;

const StyledContainer = styled.div`
  width: 100%;
`;

const StyledGridWrapp = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0px 30px;
  ${media.phone`gap: 0px 0px;`};
`;

const StyledTitle = styled.div`
  margin-top: 162px;
  grid-column: 1 / -1;
  font-style: normal;
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 91.6%;
`;

const StyledDescription = styled.div`
  margin-top: 26px;
  grid-column: 2 / span 5;
  margin-bottom: 72px;
  display: flex;
  justify-content: flex-start;
  ${media.tablet`
    grid-column: 1 / -1;
  `};
`;

const TabletContentWrapp = styled.div`
  max-width: 570px;
  font-style: normal;
  font-weight: 300;
  font-size: 2rem;
  line-height: 108.1%;
  ${media.bigDesktop`font-size: 1.875rem; max-width: 432px;`};
  ${media.phone`font-size: 1.5rem;`};
`;

const StyledContactForm = styled.div`
  grid-column: 8 / span 4;
  margin-top: 26px;
  ${media.tablet`
    grid-column: 1 / -1; margin-bottom: 32px;
  `};
`;
