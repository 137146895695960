import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import Section from "../../components/section";
import AnimationLink from "../../components/animation-link";
import DarkTurquoiseArrowIcon from "../../components/icons/dark-turquoise-arrow-icon";
import media from "../../styles/media";
import theme from "../../styles/theme";
const { colors } = theme;

const HomeHero = (props) => {
  const { description, logoImage, pageLayoutRef } = props;

  return (
    <Section>
      <StyledContainer>
        <ContentWrapp>
          {logoImage && (
            <StyledGridWrapp>
              <CoverImg>
                <Img fluid={logoImage.fluid} title="belolabs-logo" />
              </CoverImg>
            </StyledGridWrapp>
          )}
          {description && (
            <StyledGridWrapp>
              <StyledDescription>
                <TabletContentWrapp>
                  {description.description} &nbsp;
                  <AnimationLink
                    navLink="/about"
                    pageLayoutRef={pageLayoutRef}
                  >
                    Why Belo?
                    <DarkTurquoiseArrowIcon />
                  </AnimationLink>
                </TabletContentWrapp>
              </StyledDescription>
            </StyledGridWrapp>
          )}
        </ContentWrapp>
      </StyledContainer>
    </Section>
  );
};

export default HomeHero;

const StyledContainer = styled.div`
  min-height: calc(100vh - 73px);
  width: 100%;
  display: flex;
  align-items: center;
  ${media.tablet`min-height: calc(100vh - 50px);`};
`;

const ContentWrapp = styled.div`
  width: 100%;
`;

const CoverImg = styled.div`
  grid-column: 1 / span 5;
  ${media.tablet`grid-column: 1 / span 6; min-width: 268px;`};
`;

const StyledGridWrapp = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0px 30px;
  ${media.phone`gap: 0px 0px;`};
`;

const StyledDescription = styled.div`
  margin-top: 41px;
  grid-column: 8 / span 5;
  ${media.desktop`grid-column: 7 / span 6; margin-top: 120px;`};
  ${media.tablet`
    grid-column: 1 / -1;
    margin-top: 200px;
    display: flex;
    justify-content: flex-end;`};
`;

const TabletContentWrapp = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 2.5rem;
  line-height: 108.1%;
  ${media.bigDesktop`font-size: 2rem; max-width: 400px;`};
  ${media.phone`font-size: 1.6rem;`};
  a {
    display: inline-flex;
    align-items: center;
    color: ${colors.darkTurquoise};
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.41, 0, 0.019, 1);
    &:hover {
      svg {
        transform: translateX(8px);
        transition-duration: 0.3s;
      }
    }
  }
  svg {
    width: 64px;
    margin-left: 8px;
  }
`;
