import React from "react";
import { graphql } from "gatsby";
import HomeHero from "./home-hero";
import ServicesHero from "./services-hero";
import WorkHero from "./work-hero";
// import media from "../../styles/media";

// Module Hero Fragment
export const moduleHeroFragment = graphql`
  fragment ModuleHeroFragment on Node {
    ... on ContentfulModuleHero {
      __typename
      contentful_id
      name
      type
      title
      description {
        description
      }
      backgroundWgl
      logoImage {
        title
        fluid(
          maxWidth: 800
          quality: 95
          toFormat: PNG
          cropFocus: FACES
          resizingBehavior: FILL
        ) {
          ...GatsbyContentfulFluid
        }
      }
      formcarryId
    }
  }
`;

const ModuleHero = (props) => {
  const {
    title,
    type,
    description,
    logoImage,
    formcarryId,
    pageLayoutRef,
  } = props;
  switch (type) {
    case "home": {
      return (
        <HomeHero
          description={description}
          logoImage={logoImage}
          pageLayoutRef={pageLayoutRef}
        />
      );
    }

    case "services": {
      return (
        <ServicesHero
          title={title}
          description={description}
          logoImage={logoImage}
          pageLayoutRef={pageLayoutRef}
        />
      );
    }

    case "contact": {
      return (
        <ServicesHero
          title={title}
          description={description}
          logoImage={logoImage}
          formcarryId={formcarryId}
          pageLayoutRef={pageLayoutRef}
        />
      );
    }

    case "work": {
      return (
        <WorkHero
          title={title}
          logoImage={logoImage}
          pageLayoutRef={pageLayoutRef}
        />
      );
    }

    default: {
      return null;
    }
  }
};

export default ModuleHero;
